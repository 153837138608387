<template>
  <div class="home" :style="backgroundStyle">
  <div class="container">
    <!-- 女神榜按钮 -->
    <button class="btn" @click="fetchGoddessRanking">
      <img :src="goddessIcon" alt="女神榜" />
    </button>
    <!-- 土豪榜按钮 -->
    <button class="btn" @click="fetchRichManRanking">
      <img :src="richManIcon" alt="土豪榜" />
    </button>
  </div>
  <div>
    <router-link to="/new" class="top-right-button">
        <img src="@/assets/19.png" alt="活动说明" />
      </router-link>

  </div>

  <div class="content-container">
    <div v-if="currentContent === 'content1'" class="content">
      <div v-if="rankings.length > 0" class="live-stream-rankings" :style="rankingsStyle">
        <table>
          <tbody>
            <tr v-for="(item, index) in rankings" :key="item.id">
              <td>{{ index + 1 }}</td>
              <td>{{ item.touid }}</td>
              <td>{{ item.user_nicename }}</td>
              <td>{{ item.scores }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else-if="currentContent === 'content2'" class="content">
      <!-- 土豪榜内容 -->
      <div v-if="rankings.length > 0" class="live-stream-rankings" :style="rankingsStyle">
        <table>
          <tbody>
            <tr v-for="(item, index) in rankings" :key="item.id">
              <td>{{ index + 1 }}</td>
              <td>{{ item.touid }}</td>
              <td>{{ item.user_nicename }}</td>
              <td>{{ item.scores }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios';
import {activity} from '@/request/home/index.js';
import {uindex} from '@/request/home/index.js'

export default {
name: 'HomeView',
data() {
  return {
    currentContent: 'content1',
    backgrounds: {
      content1: require('@/assets/8.png'),
      content2: require('@/assets/7.png')
    },
    goddessIcon: require('@/assets/16.png'), // 女神榜默认图标
    richManIcon: require('@/assets/11.png'), // 土豪榜默认图标
    showImage: false,
    imageSrc: require('@/assets/20.png'),
    rankings: [] // 初始化为空数组，用于存储请求的数据
  }
},
computed: {
  backgroundStyle() {
    return {
      backgroundImage: `url(${this.backgrounds[this.currentContent]})`
    };
  },
  rankingsStyle() {
    return {
      padding: '0px 10px',
      borderRadius: '10px', // 圆角边框
      fontWeight: 'bold' // 字体加粗
    };
  }
},
methods: {
  toggleContent(content) {
    this.currentContent = content;
  },
  fetchGoddessRanking() {
    // 清空当前数据
    this.rankings = [];
    // 请求女神榜数据
    activity().then((res) => {
      console.log('res:', res);
      if (res.data && res.data.code === 0 && Array.isArray(res.data.data)) {
        this.rankings = res.data.data;
        this.currentContent = 'content1';
        this.goddessIcon = require('@/assets/16.png'); // 更新女神榜图标
        this.richManIcon = require('@/assets/11.png'); // 更新土豪榜图标
      } else {
        console.error('Invalid response data');
      }
    });
  },
  fetchRichManRanking() {
    // 清空当前数据
    this.rankings = [];
    // 请求土豪榜数据
    uindex().then((res) => {
      if (res.data && res.data.code === 0 && Array.isArray(res.data.data)) {
        this.rankings = res.data.data.map(item => {
          return {
            touid: item.uid, // 将 uid 映射为 touid
            ...item // 保留其他属性
          };
        });
        this.currentContent = 'content2';
        this.goddessIcon = require('@/assets/10.png'); // 重置女神榜图标
        this.richManIcon = require('@/assets/17.png'); // 重置土豪榜图标
      } else {
        console.error('Invalid response data');
      }
    });
  }
},
created() {
  // 在组件创建时请求数据
  this.fetchGoddessRanking();
}
}
</script>

<style scoped>
.home {
background-size: cover;
background-position: center;
height: 100vh;
background-color: transparent;
}
.home.content2 {
background-image: url('@/assets/8.png'); /* 土豪榜背景图片 */
}

.container {
display: flex;
justify-content: center;
align-items: center;
height: 23vh;
width: 390px;
}

.btn {
margin: 0 10px;
border: none;
background: transparent;
cursor: pointer;
}

.btn img {
width: 100px;
height: 25px;
object-fit: contain;
}

.content-container {
text-align: center;
padding: 15vh 2vw;
}

.content h1 {
margin-bottom: 10px;
font-weight: bold;
}

.content p {
font-size: 16px;
}

.top-right-button {
position: fixed;
top: 100px;
right: 15px;
padding: 5px 5px;
/* background-color: rgb(93, 169, 235); */
color: black;
border: 1px solid black;
border-radius: 5px;
cursor: pointer;
z-index: 1000;
/* transition: background-color 0.3s, border-color 0.3s; */
width: 70px;
}

.top-right-button:hover {
/* background-color: rgb(93, 169, 235);
border-color: rgb(93, 169, 235); */
}

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1050;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-modal img {
  width: 1125px; /* 设置图片宽度为1125像素 */
  max-height: 100%; /* 保持图片高度不超过弹窗高度 */
  object-fit: contain; /* 确保图片完整显示在弹窗内 */
}

.close-button {
position: fixed;
top: 20px;
right: 20px;
padding: 10px 20px;
border: 1px solid black;
border-radius: 5px;
cursor: pointer;
transition: background-color 0.3s, border-color 0.3s;
}

.close-button:hover {
background-color: black;
border-color: black;
}

.live-stream-rankings {
width: 80%;
margin: 20px auto;
}

.live-stream-rankings table {
width: 100%;
border-collapse: collapse; /* 确保边框合并 */
}

.live-stream-rankings th, .live-stream-rankings td {
padding: 10px; /* 减少内边距以提供更多空间给内容 */
text-align: left;
border: none;
background-color: transparent;
overflow: hidden; /* 隐藏溢出的文本 */
text-overflow: ellipsis; /* 使用省略号表示隐藏的文本 */
white-space: nowrap; /* 防止文本换行 */
}

.live-stream-rankings td:nth-child(3) { /* 假设 user_nicename 在第三列 */
max-width: 300px; /* 设置最大宽度，根据需要调整 */
min-width: 100px; /* 设置最小宽度，根据需要调整 */
}

.live-stream-rankings tr {
line-height: 2; /* 调整行高以提高可读性 */
}

.live-stream-rankings tr:hover {
background-color: #f0f0f0; /* 为悬停的行添加背景色 */
}
.top-right-button {
position: fixed;
top: 100px;
right: 10px;
background-color: transparent; /* 移除背景色 */
border: none; /* 移除边框 */
border-radius: 0; /* 移除圆角 */
cursor: pointer;
z-index: 1000;
display: flex; /* 使用flex布局 */
align-items: center; /* 垂直居中图标 */
justify-content: center; /* 水平居中图标 */
padding: 0; /* 移除内边距 */
width: 80px; /* 设置按钮的宽度，根据图标大小调整 */
height: 50px; /* 设置按钮的高度，根据图标大小调整 */
}

.top-right-button img {
width: 100%; /* 图标宽度填充按钮 */
height: auto; /* 图标高度自动调整 */
object-fit: contain; /* 确保图标完整显示在按钮内 */
}

</style>